<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style="enable-background:new 0 0 512.001 512.001;"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill:#FFF082;"
        d="M141.241,191.677H8.828c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h132.414
		c4.879,0,8.828,3.953,8.828,8.828S146.121,191.677,141.241,191.677z"
      />
      <path
        style="fill:#FFF082;"
        d="M123.586,297.608H61.793c-4.879,0-8.828-3.953-8.828-8.828s3.948-8.828,8.828-8.828h61.793
		c4.879,0,8.828,3.953,8.828,8.828S128.466,297.608,123.586,297.608z"
      />
    </g>
    <g>
      <path
        style="fill:#FFDC64;"
        d="M132.414,244.642H35.31c-4.879,0-8.828-3.953-8.828-8.828c0-4.875,3.948-8.828,8.828-8.828h97.103
		c4.879,0,8.828,3.953,8.828,8.828C141.241,240.69,137.293,244.642,132.414,244.642z"
      />
      <path
        style="fill:#FFDC64;"
        d="M465.252,73.491c0.775-10.556-2.688-20.052-9.741-26.741c-7.053-6.689-16.874-9.806-27.606-8.685
		c-10.753,1.09-21.159,6.229-29.301,14.465c-15.33,15.501-33.871,49.3-46.171,73.666c-3.705-24.366-10.328-58.165-20.192-73.666
		c-5.237-8.237-13.832-13.375-24.2-14.465c-10.346-1.121-21.257,1.996-30.669,8.685c-9.412,6.69-16.225,16.185-19.172,26.741
		c-2.956,10.556-1.695,20.905,3.544,29.133c15.983,25.126,77.332,60.113,79.943,61.587c1.16,0.66,2.49,0.983,3.87,0.983
		c1.373,0,2.771-0.437,4.129-1.066l0.087,0.084c3.13-1.474,76.817-36.461,101.652-61.582
		C459.575,94.397,464.486,84.048,465.252,73.491z M277.406,91.492c-2.909-4.574-3.612-10.319-1.965-16.185
		c1.638-5.862,5.421-11.138,10.654-14.858c4.535-3.229,9.699-4.943,14.742-4.943c0.767,0,1.536,0.039,2.297,0.12
		c5.754,0.608,10.528,3.462,13.437,8.034c8.87,13.94,15.505,49.452,19.1,74.724C314.897,125.332,286.282,105.444,277.406,91.492z
		 M439.69,91.496c-13.777,13.935-49.405,33.832-74.793,46.883c12.508-25.267,31.666-60.78,45.451-74.72
		c4.522-4.574,10.301-7.426,16.27-8.034c0.79-0.082,1.574-0.12,2.34-0.12c5.043,0,9.602,1.72,12.999,4.943
		c3.922,3.72,5.845,8.995,5.415,14.858C446.95,81.172,444.222,86.918,439.69,91.496z"
      />
    </g>
    <path
      style="fill:#FFC850;"
      d="M345.557,165.194c-1.009,0-1.978-0.172-2.876-0.513l-115.804-44.138
	c-4.306-1.642-5.811-6.694-3.356-11.285c2.439-4.59,7.932-6.991,12.218-5.345l113.025,43.078l128.216-43.078
	c4.858-1.642,9.514,0.755,10.334,5.345c0.838,4.59-2.45,9.642-7.335,11.285l-131.369,44.138
	C347.596,165.022,346.566,165.194,345.557,165.194z"
    />
    <path
      style="fill:#B4E66E;"
      d="M441.148,474.16H141.01c-9.751,0-16.262-7.904-14.542-17.655l49.81-282.483
	c1.719-9.751,11.018-17.655,20.768-17.655h300.138c9.751,0,16.262,7.904,14.542,17.655l-49.81,282.483
	C460.196,466.255,450.898,474.16,441.148,474.16z"
    />
    <g>
      <polygon
        style="fill:#C8FF82;"
        points="138.92,385.884 211.63,474.16 291.079,474.16 152.928,306.436 	"
      />
      <path
        style="fill:#C8FF82;"
        d="M189.525,157.953c-6.618,2.769-11.985,8.904-13.248,16.068l-9.34,52.966l203.59,247.172h70.621
		c2.588,0,5.13-0.587,7.52-1.587L189.525,157.953z"
      />
      <polygon
        style="fill:#C8FF82;"
        points="485.263,324.091 347.114,156.367 267.666,156.367 471.254,403.539 	"
      />
      <path
        style="fill:#C8FF82;"
        d="M511.724,174.022c1.719-9.751-4.792-17.655-14.542-17.655h-70.621l72.711,88.276L511.724,174.022z"
      />
    </g>
    <path
      style="fill:#A0D755;"
      d="M191.885,385.884l40.47-229.517h-35.31c-9.751,0-19.049,7.904-20.768,17.655l-49.81,282.483
	c-1.719,9.751,4.791,17.655,14.542,17.655h300.138c9.75,0,19.049-7.905,20.768-17.655H250.053
	C211.051,456.505,185.007,424.886,191.885,385.884z"
    />
    <g>
      <path
        style="fill:#B4E66E;"
        d="M250.054,456.505c-39.003,0-65.046-31.618-58.168-70.621l4.67-26.483l-43.626-52.966l-14.009,79.448
		l72.711,88.276h79.448l-14.542-17.655H250.054z"
      />
      <path
        style="fill:#B4E66E;"
        d="M224.573,200.505l-35.048-42.551c-1.527,0.639-2.963,1.496-4.322,2.465
		c-0.203,0.146-0.409,0.286-0.608,0.438c-1.343,1.026-2.601,2.175-3.69,3.471c-0.017,0.022-0.031,0.046-0.049,0.07
		c-1.009,1.212-1.837,2.557-2.563,3.953c-0.181,0.35-0.354,0.701-0.515,1.059c-0.663,1.476-1.217,3.007-1.5,4.614l-9.34,52.966
		l43.626,52.966L224.573,200.505z"
      />
      <path
        style="fill:#B4E66E;"
        d="M445.102,473.703c1.215-0.27,2.409-0.647,3.563-1.13c0.001,0,0.001,0,0.001,0l-13.235-16.068
		h-79.448l14.542,17.655h70.621c1.289,0,2.567-0.147,3.819-0.417C445.013,473.733,445.056,473.713,445.102,473.703z"
      />
    </g>
    <polygon
      style="fill:#FFDC64;"
      points="373.597,156.367 320.631,156.367 264.596,474.16 317.561,474.16 "
    />
    <polygon
      style="fill:#FFC850;"
      points="320.674,456.505 267.709,456.505 264.596,474.16 317.561,474.16 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
